import React from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/seo";
import { Layout } from "../components/Layout/layout"
import InfoWrapper from "../components/Info/InfoWrapper";

export default function TermsPage({
  data: { terms },
}) {

  const termsData = terms.data.body.reduce((acc,curr) => {
    const primary = curr.primary;
    const item = primary.menu_title;
    const content = primary.content.html;

    acc.push(Object.assign({content: content},
      item === null ? null : {item},
    ))

    return acc;
  },[])


  return (
    <Layout navBorder>
      <Seo
          title='Terms of Service'
        />
      <InfoWrapper title='Terms of Service' data={termsData}/>
    </Layout>
  )
}

export const query = graphql`
  query {
    terms: prismicTermsOfService(id: {eq: "e75d5c8b-6dca-56d8-8f98-cb9eda933e86"}) {
      data {
        body {
          ... on PrismicTermsOfServiceDataBodyContentGroup {
            id
            primary {
              content {
                html
              }
              menu_title
            }
            items {
              question
              answer {
                html
              }
            }
          }
        }
      }
      id
    }
  }
`